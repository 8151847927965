<template lang="pug">
    auth
</template>

<script>
import Auth from "../components/auth/auth";

export default {
    components: { Auth },
    async mounted() {
        await this.$store.dispatch("setPage", "auth");
    },
};
</script>
